import React  from "react";
import Layout from "../components/layout/layout";
import Section from "../components/shared/section";
import { makeStyles, styled } from "@material-ui/core/styles";
import { Grid as MuiGrid, Typography as MuiTypography } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import cx from "classnames";
import Subtitle from "../components/shared/subtitle";
import Pricing_1 from "../assets/images/pricing/1@3x.svg";
import Pricing_2 from "../assets/images/pricing/2@3x.svg";
import Pricing_3 from "../assets/images/pricing/3@3x.svg";
import Pricing_4 from "../assets/images/pricing/4@3x.svg";
import { IMAGES } from "../constants/images";

const Typography = styled(MuiTypography)(spacing);
const Grid = styled(MuiGrid)(spacing);

const CARD_ITEMS = [
    {
        icon: Pricing_1,
        title: 'App for iOS, Android & Web',
        check: true,
    },
    {
        icon: Pricing_2,
        title: 'Add as many users as you want!',
        check: true,
    },
    {
        icon: Pricing_3,
        title: 'No storage limits.',
        check: false,
    },
    {
        icon: Pricing_4,
        title: 'No hidden fees. We promise.',
        check: false,
    },

]

const useStyles = makeStyles((theme) => ({
    section: {
        paddingBottom: 0,
    },
    mainSection: {
        flex: "auto",
        zIndex: 100,
    },
    columnContainer: {
        display: "flex",
        flexDirection: "column",

        [theme.breakpoints.down(1024)]: {
            width: '100%',
            alignItems: 'center',
        },
    },
    card: {
        borderRadius: 10,
        display: "flex",
        justifyContent: "space-between",
        padding: '32px 36px',
        alignItems: "center",
        marginBottom: 35,

        [theme.breakpoints.down(840)]: {
            justifyContent: 'center'
        },
    },
    cardContent: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        height: '100%',

        [theme.breakpoints.down(840)]: {
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
    cardIcon: {
        maxWidth: 63,
        maxHeight: 50,
        height: 50,
        width: 'fit-content',
        marginBottom: 13,
    },
    cardTitle: {
        fontWeight: 500,
        fontSize: '1.4rem',
        color: '#F15A29',
        maxWidth: 195,

        [theme.breakpoints.down(840)]: {
            maxWidth: '100%'
        },
    },
    cardTitleBlack: {
        color: '#000000',
        maxWidth: 175,

        [theme.breakpoints.down(840)]: {
            maxWidth: '100%'
        },
    },
    cardIconCheck: {
        width: 36,
        height: 36,
        marginLeft: 30,
    },
    title: {
        width: '100%',
        fontSize: '3rem',
        lineHeight: 1.04,
        marginBottom: 5,

        [theme.breakpoints.down(1024)]: {
            textAlign: 'center'
        },
    },
    blueText: {
        color: '#0C64CE',
    },
    subtitle: {
      marginTop: 15,

        [theme.breakpoints.down(1024)]: {
            textAlign: 'center',
            margin: 'auto',
            marginTop: 15,
            marginBottom: 65,
        },
    },
    descriptionContainer: {
        marginTop: 0,
        marginBottom: 65,
    },
}));

const Pricing = ({path}) => {
  const classes = useStyles();
  let imageMain = IMAGES.pricing.png;

  const Card = ({ icon, title, check}) => (
    <Grid item xs={12} sm={6} md={3} className={classes.card}>
        <div className={classes.cardContent}>
            <img src={icon} alt="icon" className={classes.cardIcon}/>
            <div className={cx(classes.cardTitle,{[classes.cardTitleBlack]: !check})}>
                {title}
            </div>
        </div>
    </Grid>
  )

  return (
    <>
        {
            imageMain &&
            <Layout SeoProps={{ title: `Pricing` }} HeaderProps={{ path }}>
                <Section
                  container
                  direction="column"
                  alignItems="center"
                  pt={10}
                  pb={15}
                  sectionClassName={classes.mainSection}
                  innerClassName={classes.section}
                >
                    <Grid container mt={15} mb={2}
                          alignItems="center"
                          justify="space-between"
                          classes={{
                              root: classes.descriptionContainer,
                          }}>
                        <div className={classes.columnContainer}>
                            <Typography variant="h2" mr={1} classes={{
                                root: classes.title,
                            }}>
                                Slate packages <br/>
                                starting at <span className={classes.blueText}>$34.99</span>
                            </Typography>
                            <Subtitle mb={8} align={'left'} className={classes.subtitle}>
                                Our packages will get you up and running quickly with
                                built-in templates, pre-configured views, and sample slates.
                            </Subtitle>
                        </div>
                    </Grid>
                    <Grid container
                          justify={'center'}
                          spacing={2}
                    >
                        {CARD_ITEMS.map(( props, index) => (
                          <Card
                            key={index}
                            {...props}
                          />
                        ))}
                    </Grid>
                </Section>
            </Layout>
        }
    </>
  )
};


export default Pricing;
